.reservation_page {
    height: 100vh;
    width: 100vw !important;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.reservation_page .text_part .reservation_logo {
    padding: 45px 0px 45px 68px !important;
}

.reservation_page .text_part .reservation_logo div img {
    height: 28px;
    width: 179px;
}

.reservation_page .text_part .text_box {
    padding: 40px 83px 0px 82px;
}

@media screen and (min-width:2000px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 95%;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 44px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 89%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 37px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 161px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 80% !important;
        height: 95% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 350px !important;
        height: 450px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 30px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: 100%;
        height: 480px;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 48.59px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1800px) and (max-width:1999px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 65%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 44px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa2 {
        width: 75%;
        max-width: 600px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 89%;
        max-width: 650px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 37px;
    }

    .reservation_page .text_part .text_box .pa4 {
        width: 75%;
        font-family: "Inter", sans-serif;
        color: #2E2E2E;
        font-weight: bold !important;
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
        letter-spacing: 0.3px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 161px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 46.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 85% !important;
        height: 100% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 350px !important;
        height: 400px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 15px;
        background-size: contain !important;
        margin-top: -5px;
        margin-bottom: 38px !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: 100%;
        height: 450px;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: 100%;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 48.59px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1430px) and (max-width:1799px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px !important;
        margin-bottom: 37px !important;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        /* max-width: 650px; */
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px !important;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 19px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.587vh;
        background-color: #DFDDD3;
        position: relative;
        margin-top: 32px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 92% !important;
        height: 83% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 281px !important;
        height: 350px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 10px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 404px;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: auto;
        height: 404px;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 68px 32px 32px 32px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        /* margin: 9px 0p */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 29.05px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width: 1430px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 40px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px;
        margin-bottom: 37px !important;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px !important;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 19px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
        margin-top: 24px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-img-text-box-background {
        width: 91% !important;
        height: 83% !important;
        background-image: url(../../assests/launcher_page_img/reservation-text-image.png);
        background-repeat: round;
        position: absolute;
        margin-top: 50px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .image_iphone {
        width: 281px !important;
        height: 330px !important;
        background-image: url(../../assests/launcher_page_img/iPhone-14-Pro-Mockup\ 1.svg);
        background-repeat: round;
        position: absolute;
        right: 1px;
        background-size: contain !important;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 404px;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: auto;
        height: 404px;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -205px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 54vh;
        display: flex;
        flex-direction: column;
        /* justify-content: flex-end; */
        padding: 36px 28px 28px 28px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 24px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41.783vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px;
        margin-bottom: 37px !important;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px !important;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 19px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 37vh;
        background-color: #DFDDD3;
        position: relative;
        margin-top: 24px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -205px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 55vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 48px 28px 0px 28px;
        margin-bottom: 28px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 30px;
        width: 36.42px;
        object-fit: contain;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 24px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px;
        margin-bottom: 30px !important;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px !important;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 19px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh;
        background-color: #DFDDD3;
        position: relative;
        margin-top: 18px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 25px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -230px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 59vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 48px 22px 22px 22px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 20px 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 28px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 20px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px;
        margin-bottom: 30px !important;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px !important;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 19px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
        margin-top: 24px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 25px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -230px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 64vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 30px 18px 18px 18px;
        gap: 12px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 28px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 20px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px;
        margin-bottom: 30px !important;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px !important;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 19px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 5px !important;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh !important;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -230px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 2px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 62vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 28px 16px 16px 16px;
        gap: 12px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 20px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px;
        margin-bottom: 30px !important;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px !important;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 19px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        margin-top: 8px;
        letter-spacing: 0.3px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 42vh !important;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -185px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 21px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 21px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 65vh;
        display: flex;
        flex-direction: column;
        padding: 26px 16px 16PX 16PX;
        justify-content: flex-start;
        gap: 12px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }
}

@media screen and (min-width: 1430px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 83px 0px 82px;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 60vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 40vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px !important;
        margin-bottom: 10px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41vh !important;
        background-color: #DFDDD3;
        position: relative;
        margin-top: 12px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 92%;
        height: auto;
        object-fit: cover;
        background-repeat: no-repeat;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        margin-top: 8px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -160px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 16px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 64vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px;
        gap: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }
}

@media screen and (min-width: 1430px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 45px 0px 45px 82px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 0px 83px 0px 82px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        max-width: 700px;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 10px;
        padding-right: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 97%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px !important;
        margin-bottom: 12px !important;
    }

    .reservation_page .text_part .text_box button {
        width: 411px;
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18.5px 18px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 40vh !important;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        margin-top: 8px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 16px 16px 16px 16px;
        margin-bottom: 20px !important;
        gap: 12px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 7px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
        margin-top: 32px;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 32px 28px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 28px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        flex-direction: column;
        padding: 32px 28px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 28px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 40vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        flex-direction: column;
        padding: 32px 28px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 28px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 37px 0px 37px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 15px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 35px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 62vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 32px 28px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 28px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 750px) and (max-height: 799px) {

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 15px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 62vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 24px 18px;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 700px) and (max-height: 749px) {

    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 72px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 64vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 24px 18px;
        gap: 12px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 12px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 630px) and (max-height: 699px) {

    .reservation_page .text_part .reservation_logo {
        padding: 25px 0px 25px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
        margin-bottom: 18px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 62vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 24px 16px;
        gap: 12px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (min-height: 550px) and (max-height: 629px) {

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 59vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 41vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 12px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 12px;
        margin-bottom: 12px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 40vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        padding: 20px 16px;
        gap: 12px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }
}

@media screen and (min-width:1300px) and (max-width:1429px) and (max-height: 549px) {

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 72px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 0px 72px 0px 72px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 60vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 40vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 48px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 15px;
        margin-bottom: 15fpx !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 150px;
        line-height: 19px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 34vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        margin-top: 8px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 60vh;
        display: flex;
        flex-direction: column;
        padding: 20px 16px;
        gap: 12px !important;
        z-index: 5000;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 24px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 35vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 61vh;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 26px;
        gap: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 28px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 13px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 950px) and (max-height: 1009px) {

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 32px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 24px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 62vh;
        display: flex;
        flex-direction: column;
        padding: 26px;
        gap: 16px;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 28px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 13px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 40px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 58%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 42%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 252px;
        height: auto;
        margin-top: 10px;
        padding: 0px 10px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -175px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 62vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 26px;
        gap: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 20px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 18px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 40vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 25px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 58%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 42%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 232px;
        height: auto;
        margin-top: 10px;
        padding: 0px 10px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -160px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 64vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 26px;
        gap: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 14px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 15px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 58%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 42%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 222px;
        height: auto;
        margin-top: 10px;
        padding: 0px 10px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -180px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 2px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 64vh;
        display: flex;
        flex-direction: column;
        padding: 24px;
        gap: 14px;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 90%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 14px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 15px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 58%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 42%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 242px;
        height: auto;
        margin-top: 2px;
        padding: 0px 10px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -200px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 2px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 62vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 24px;
        gap: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 8px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 38vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        margin-top: 8px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 58%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 42%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 213px;
        height: auto;
        margin-top: 2px;
        padding: 0px 10px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -180px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        color: #2E2E2E;
        font-weight: 700 !important;
        line-height: 20px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 56vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px;
        gap: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 12px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        margin-top: 8px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 58%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 42%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 190px;
        height: auto;
        margin-top: 2px;
        padding: 0px 10px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -160px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 16px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 64vh;
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 12px;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }
}

@media screen and (min-width:1200px) and (max-width:1299px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 64px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 8px 64px 0px 64px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 57vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 43vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 45px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 14px;
        margin-bottom: 14px !important;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        line-height: 18px;
        padding: 18px 140px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 21px;
        margin-top: 8px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 58%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 42%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 175px;
        height: auto;
        margin-top: 2px;
        padding: 0px 10px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        width: 100%;
        height: 64vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px;
        gap: 10px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 10%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }
}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 18px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 90%;
        margin-top: 11px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

}


@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;

    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        max-height: 320px;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 62vh;
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 18px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 18px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 11px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 41.51vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        max-height: 310px;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 18px;
        gap: 16px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 31px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 37px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 30vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        max-height: 310px;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 220px;
        height: auto;
        margin-top: 10px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 58vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }


    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 36px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 35vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        max-height: 300px;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 220px;
        height: auto;
        margin-top: 7px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 56vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 36px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 34vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        max-height: 300px;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -165px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 54vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 12px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 36px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 30px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 13px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 7px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 9.5px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 17px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9.5px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 17px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 12px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 0px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 36px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 34vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 12px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        margin-top: 8px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 17px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 17px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 63vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 10px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 95%;
        margin-top: 10px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }

}

@media screen and (min-width:1000px) and (max-width:1199px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 55px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 0px 55px 0px 55px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 56vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 44vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        width: 100%;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 36px;
        padding-top: 0px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 92%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 12px 120px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 32vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        margin-top: 5px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 160px;
        height: auto;
        margin-top: 0px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -120px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 8px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 13px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 64vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 10px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 10px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 13%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 28px;
        width: 32px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 87%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height:1010px) {

    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 38px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 65vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 16px;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 38px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 65vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 16px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 38px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 17px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 22px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 11px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 22px;
        margin-top: 5px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 65vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 38px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 210px;
        height: auto;
        margin-top: 5px;
        padding: 0px 13px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -130px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 16px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 38px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 200px;
        height: auto;
        margin-top: 5px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 14px;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 15px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 38px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 10px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 200px;
        height: auto;
        margin-top: 5px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -140px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 20px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 20px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 18px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 12px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 38px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 16px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 0px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 0px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -110px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 12px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 0px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 38px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 22px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 18px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 16px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 0px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 0px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -110px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 62vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 10px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 6px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
    }

}

@media screen and (min-width:900px) and (max-width:999px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 0px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
        margin-top: 12px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 16px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 36vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 0px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 0px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -110px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 16px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 62vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 14px;
        gap: 10px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 15px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height:1010px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 30vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 65vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 950px) and (max-height: 1009px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 30vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 30px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 65vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        justify-content: flex-start;
        gap: 14px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 880px) and (max-height: 949px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 15px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 30vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 30px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 65vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 14px;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 800px) and (max-height: 879px) {
    .reservation_page .text_part .reservation_logo {
        padding: 35px 0px 35px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 30vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 750px) and (max-height: 799px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 30vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 20px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -90px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 60vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 15px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 700px) and (max-height: 749px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 28px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 30vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: contain;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 10px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 18px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 18px;
        margin-top: 3px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 62vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 15px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 630px) and (max-height: 699px) {
    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 30px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 18px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 24px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 34vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 16px;
        margin-top: 2px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 62vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 14px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 8px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 18px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (min-height: 550px) and (max-height: 629px) {
    .reservation_page .text_part .reservation_logo {
        padding: 30px 0px 30px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 10px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 26px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
        margin-top: 16px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 18px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 32vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 20px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 10px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -100px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 10px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 15px;
        margin-top: 1px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 65vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 10px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 11px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 28px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 6px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 15px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:830px) and (max-width:899px) and (max-height: 549px) {
    .reservation_page .text_part .reservation_logo {
        padding: 28px 0px 28px 40px !important;
    }

    .reservation_page .text_part .text_box {
        padding: 0px 40px 0px 40px !important;
    }

    .reservation_page .text_part {
        height: 100vh;
        width: 55vw;
        background: #ECEBE4;
    }

    .reservation_page .cards_part {
        background: #dedcd3;
        height: 100vh;
        width: 45vw;
    }

    .reservation_page .text_part .text_box .pa1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 23px;
        font-weight: lighter !important;
        line-height: 24px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.3px;
        margin-top: 16px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        border: none;
        outline: none;
        padding: 14px 100px;
        line-height: 16px !important;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100%;
        height: 34vh;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 90%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 15px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        margin-top: 0px;
        letter-spacing: 0.3px;

    }

    .reservation_page .cards_part .img_text_box .mockup_box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .mockup_text {
        width: 60%;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image {
        width: 40%;
        display: flex;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .mockup_box .iphone_image img {
        width: 180px;
        height: auto;
        margin-top: 0px;
        padding: 0px 15px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 24px;
        margin-top: -110px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 16px;
    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 16px;
        margin-top: 0px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box {
        height: 63vh;
        position: absolute;
        bottom: 1px;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 10px !important;
        justify-content: flex-start;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 26px;
        width: 31.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 20px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 4px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 15px;
        letter-spacing: 0.3px;
    }

}

@media screen and (min-width:823px) and (max-width:829px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 500px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 35px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 835px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:761px) and (max-width:822px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 500px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 20px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 35px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 880px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:740px) and (max-width:760px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 530px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 35px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 895px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:723px) and (max-width:739px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 540px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 35px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 895px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:700px) and (max-width:722px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 550px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 425px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 35px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 897px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 25px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:676px) and (max-width:699px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 550px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 390px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 35px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 865px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:604px) and (max-width:675px) {

    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 575px;
    }

    .reservation_page .text_part {
        height: 507px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 390px;
        width: 100vw;
        margin-top: 90px;
        padding-bottom: 20px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 35px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 860px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36.42px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:556px) and (max-width:603px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 650px;
    }

    .reservation_page .text_part {
        height: 535px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 350px;
        width: 100vw;
        margin-top: 92px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 22px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 827px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:518px) and (max-width:555px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 32px 0px 32px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 30px 35px 30px 35px;
        height: 650px;
    }

    .reservation_page .text_part {
        height: 535px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 350px;
        width: 100vw;
        margin-top: 92px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 22px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 847px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 22px 35px 0px 35px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 92%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:504px) and (max-width:517px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 16px 22px 16px 22px;
        height: 620px;
    }

    .reservation_page .text_part {
        height: 487px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 300px;
        width: 100vw;
        margin-top: 76px;
        padding-bottom: 10px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 130px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 0 22px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 770px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:427px) and (max-width:503px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 700px;
    }

    .reservation_page .text_part {
        height: 512px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 265px;
        width: 100vw;
        margin-top: 71px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 735px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:402px) and (max-width:426px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 700px;
    }

    .reservation_page .text_part {
        height: 512px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 265px;
        width: 100vw;
        margin-top: 71px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 775px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:393px) and (max-width:401px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 750px;
    }

    .reservation_page .text_part {
        height: 653px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 260px;
        width: 100vw;
        padding-top: 0px;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-right: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 775px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
        margin-right: 10px;
        padding-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        margin-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:388px) and (max-width:392px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 750px;
    }

    .reservation_page .text_part {
        height: 653px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 260px;
        width: 100vw;
        padding-top: 0px;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
        padding-right: 20px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 800px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 12%;
        margin-right: 10px;
        padding-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 36px;
        width: 36px;
        margin-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:348px) and (max-width:387px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 24px 0px 24px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 750px;
    }

    .reservation_page .text_part {
        height: 656px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 230px;
        width: 100vw;
        margin-top: 75px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 795px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 14%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
        padding-bottom: 12px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:326px) and (max-width:347px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 19px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 780px;
    }

    .reservation_page .text_part {
        height: 682px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 225px;
        width: 100vw;
        margin-top: 63px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 775px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:321px) and (max-width:325px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 15px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 820px;
    }

    .reservation_page .text_part {
        height: 720px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 210px;
        width: 100vw;
        margin-top: 65px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 760px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (min-width:316px) and (max-width:320px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 15px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 820px;
    }

    .reservation_page .text_part {
        height: 720px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 210px;
        width: 100vw;
        margin-top: 65px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 785px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}

@media screen and (max-width:315px) {
    .reservation_page {
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
        height: max-content;
        width: 100%;
    }

    .reservation_page .text_part .reservation_logo {
        padding: 20px 0px 20px 15px !important;
        position: fixed;
        background: #ecebe4;
        top: 0px;
        width: 100vw !important;
        z-index: 5000;
    }

    .reservation_page .text_part .text_box {
        padding: 22px 16px;
        height: 840px;
    }

    .reservation_page .text_part {
        height: 704px;
        width: 100vw;
        background: #ECEBE4;
    }

    .reservation-specific {
        background: #dedcd3;
        height: 205px;
        width: 100vw;
        margin-top: 68px;
        padding-bottom: 0px;
    }

    .reservation_page .text_part .text_box .pa1 {
        font-family: "Inter", sans-serif;
        font-size: 32px;
        font-weight: lighter !important;
        line-height: 40px;
    }

    .reservation_page .text_part .text_box .pa3 {
        width: 95%;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 24px;
        letter-spacing: 0.3px;
        margin-top: 24px;
    }

    .reservation_page .text_part .text_box button {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px;
        border: none;
        outline: none;
        padding: 18px 18px;
        line-height: 18px;
        background-color: #2E2E2E;
        color: white;
        border-radius: 2px;
        width: 100%;
    }

    .reservation_page .cards_part .img_text_box {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box {
        width: 100vw;
        height: 100%;
        background-color: #DFDDD3;
        position: relative;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img {
        width: auto;
        height: 100%;
        display: flex;
        padding: 16px;
        justify-content: center;
    }

    .reservation_page .cards_part .img_text_box .card-img-text-box .card-team-img img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 1px solid #000;
        border-radius: 8px;
    }

    .reservation_page .cards_part .img_text_box .pa1 {
        width: 90%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 30px;
        margin-top: 15px;
        letter-spacing: 0.3px;
        padding-left: 24px;
    }

    .reservation_page .cards_part .img_text_box .list_text {
        padding: 0px 17px;
        margin-top: -270px;
        padding-left: 40px;
    }

    .reservation_page .cards_part .img_text_box .list_text p {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        color: #2E2E2E;
        font-weight: lighter;
        line-height: 35px;

    }

    .reservation_page .cards_part .img_text_box .list_text li {
        font-family: "Inter", sans-serif;
        font-size: 9px;
        color: #2E2E2E;
        font-weight: bold !important;
        line-height: 14px;
    }

    .reservation_page .cards_part .img_text_box .reservation_page_box {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 825px;
        background: #DEDCD3;
        height: max-content;
        width: 100%;
        align-items: center;
        padding: 16px 16px 0px 16px;
        gap: 16px;
    }

    .card-bottom {
        margin-bottom: 20px !important;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 {
        height: max-content;
        width: 100%;
        background: #ECEBE5;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 24px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon {
        height: 100%;
        width: 16%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_icon img {
        height: 44px;
        width: 32px;
        object-fit: contain;
        margin-right: 16px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text {
        width: 88%;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p1 {
        font-family: "Inter", sans-serif;
        font-size: 18px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 23px;
        letter-spacing: 0.4px;
    }

    .reservation_page .cards_part .img_text_box .reservation_crad_box .card1 .card_text .card_p2 {
        font-family: "Inter", sans-serif;
        width: 100%;
        margin-top: 12px;
        font-size: 14px;
        color: #2E2E2E;
        font-weight: lighter !important;
        line-height: 19px;
        letter-spacing: 0.4px;
    }
}