* {
    margin: 0px;
    padding: 0px;
}

p {
    margin-bottom: 0px !important;
}

.minimis_launcher {
    height: 632px;
    width: 100vw;
    background: #ECEBE5;
}

.phone_mockup_section {
    display: flex;
}

.minimis_launcher .launcher_navbar {
    max-height: 400px;
    height: auto;
    min-height: 100px;
    width: 100vw;
    padding: 45px 55px;
    display: flex !important;
    align-items: center !important;
    z-index: 5000 !important;
}

.minimis_launcher .section1 {
    width: 50%;
    margin-left: 145px;
    margin-top: 90px;
}

.minimis_launcher .section1 .p1 {
    width: 60%;
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: lighter !important;
    line-height: 42px;
}

.minimis_launcher .section1 .p2 {
    width: 60%;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    color: #2e2e2e !important;
    font-weight: lighter !important;
    -webkit-text-stroke: 0px black;
    line-height: 24px;
    margin-top: 20px;
    letter-spacing: 0.3px;
}

.minimis_launcher .section1 input {
    background: #DFDDD3;
    outline: none;
    border: none;
    padding: 11px 12px;
    width: 243px;
    border-radius: 2px;
    margin-right: 4px;
}

.minimis_launcher .section1 input::placeholder {
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    color: #8A8A8D;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.minimis_launcher .section1 button {
    width: 100%;
    color: var(--primary-beige, #ECEBE5);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    -webkit-text-stroke: 0px #ECEBE5 !important;
    width: 152px;
    font-weight: 300;
    line-height: normal;
    padding: 11px 4px;
    color: white;
    background: #2e2e2e;
    outline: #2e2e2e;
    border: none;
    border-radius: 2px;
}

.minimis_launcher .section1 {
    width: 50%;
}

.minimis_launcher .section2 img {
    height: 687px;
    width: 552px;
    margin-right: 100px;
}

.addictiveApp_cards {
    height: 501px;
    width: 100vw;
    background: #DFDDD3;
    padding: 0px 60px;
    display: flex;
    align-items: center;
}

.addictiveApp_cards .app_cards img {
    height: 350px;
    width: 176px;
    border: 1px solid #7b7b78;
    border-radius: 8px;
}

.addictiveApp_cards .app_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    width: 60%;
}

.addictiveApp_cards .addictiveApp_text {
    width: 40%;
    padding: 0px 30px 0px 72px;
}

.addictiveApp_cards .addictiveApp_text .p1 {
    font-family: "Inter", sans-serif;
    font-size: 30px;
    font-weight: lighter !important;
    line-height: 42px;
    -webkit-text-stroke: 0.5px black;
}

.addictiveApp_cards .addictiveApp_text .p2 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: lighter !important;
    -webkit-text-stroke: 0.2px black;
    line-height: 25px;
    margin-top: 20px;
    letter-spacing: 0.3px;
}

.call_newport_section {
    width: 100vw;
    height: 400px;
    background: #151E1D;
    background-image: url('../../assests/launcher_page_img/black\ divider\ image.png');
    background-size: cover;
    background-position: center;
}

.call_newport_section .call_newport_text {
    width: 715px;
    box-sizing: border-box;
    height: 100%;
    padding: 0px 168px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.call_newport_section .call_newport_text .p1 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: lighter !important;
    line-height: 24px;
    color: #ECEBE5;
    letter-spacing: 0.3px;
    margin-bottom: -16px !important;
}

.call_newport_section .call_newport_text .p2 {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: lighter !important;
    line-height: 24px;
    color: #ecebe5bd !important;
    -webkit-text-stroke: 0px !important;
    letter-spacing: 0.5px;
}

.timeLimit_cards {
    height: 690px;
    width: 100vw;
    background: #DFDDD3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeLimit_cards .app_cards img {
    height: 455px;
    width: 230px;
}

.timeLimit_cards .app_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
}

.timeLimit_cards .p1 {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: lighter !important;
    line-height: 42px;
    -webkit-text-stroke: 0.2px #000;
    padding: 80px 0px 40px 0px;
}

.welcome_screen_cards {
    height: 685px;
    width: 100vw;
    background: #ECEBE5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.welcome_screen_cards,
.p1 {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    font-weight: lighter !important;
    line-height: 42px;
    -webkit-text-stroke: 0px #000 !important;
    padding: 35px 0px;
}

.welcome_screen_cards .app_cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
}

.welcome_screen_cards .app_cards img {
    height: 455px;
    width: 230px;
}

.early_access_cards {
    height: 168px;
    width: 100vw;
    background: #DFDDD3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 140px 0px 140px;
}

.early_access_cards .p1 {
    font-family: "Inter", sans-serif;
    font-size: 30px;
    font-weight: lighter !important;
    -webkit-text-stroke: 0.5px black;
}

.early_access_cards .p2 {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: lighter !important;
    -webkit-text-stroke: 0.2px black;
    line-height: 25px;
    letter-spacing: 0.3px;
}

.early_access_cards input {
    background: #ECEBE5;
    outline: none;
    border: none;
    padding: 11px 12px;
    width: 243px;
    border-radius: 2px;
    margin-right: 4px;
}

.early_access_cards input :focus {
    background: #ECEBE5 !important;
}

.early_access_cards input::placeholder {
    font-size: 18px;
    font-family: 'Inter', sans-serif;
    color: #8A8A8D;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.early_access_cards button {
    width: 100%;
    color: var(--primary-beige, #ECEBE5);
    font-family: 'Inter', sans-serif;
    font-size: 16px !important;
    -webkit-text-stroke: 0.1px #ECEBE5;
    width: 152px;
    font-weight: 500;
    line-height: normal;
    padding: 11px 2px;
    color: white;
    background: #2e2e2e;
    outline: #2e2e2e;
    border: none;
    border-radius: 2px;
}

/* ######## android download review styling ########  */

.android-main-container {
    width: 100%;
    background: #ECEBE5;
    padding: 70px 168px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
}

.android-google-container {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 7%;
}

.android-get-google {
    height: 115px;
    width: 57%;
    min-width: 574px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 175px;
    margin-top: 35px;
    margin-bottom: 20px;
}

.android-get-google-text {
    width: 36%;
    height: 62px;
    display: flex;
    align-items: center;
}

.android-get-google-text p {
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
}

.android-get-google-launched {
    display: none;
}

.android-get-google-image {
    width: 36.55%;
    height: 62px;
    max-width: 200px;
    display: flex;
    align-items: center;
}

.android-get-google-image img {
    width: 100%;
}

.android-google-mobile {
    width: 83%;
    max-width: 480px !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.android-google-mobile img {
    width: 100%;
    min-width: 445px;
    max-width: 480px !important;
}

.android-download-review {
    width: 52% !important;
    min-width: 574px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: -37%;
}

.android-download-playstore {
    width: 100%;
    display: flex !important;
    background-color: #202124 !important;
    border-radius: 8px !important;
}

.android-download-playstore img {
    width: 100%;
}

.android-download-users-review {
    width: 100%;
    height: 307px;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px 28px 28px 28px;
}

.android-download-app-rating {
    width: 100%;
    display: flex;
    margin-bottom: 12px;
    align-items: center !important;
}

.android-download-rating {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    right: 1px;
    gap: 16px;
}

.android-downlod-rating-review-center {
    border: 1px solid #2e2e2e;
    opacity: 10%;
}

.android-download-rating-text p {
    color: #2e2e2e;
    opacity: 50%;
}

.android-download-app-review {
    width: 100%;
    margin-top: 15px;
}

.android-download-app-review-light-text {
    font-family: 'Inter', system-ui !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 24px !important;
    color: black !important;
    opacity: 100% !important;
}

.android-download-app-review-bold-text {
    font-family: 'Inter', system-ui !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    color: black !important;
    opacity: 100% !important;
}

.android-download-app-review p .android-download-app-review-text {
    font-weight: 500 !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
}

.footer_section {
    width: 100vw;
    height: 124px;
    background: #151E1D;
    display: flex;
    justify-content: space-between;
    padding: 0px 110px 0px 55px;
    align-items: center;
}

.footer_section .footer-img {
    height: 24px;
    display: flex;
    justify-content: center;
}

.footer_section .footer-img img {
    height: auto;
    width: auto;
}

.footer_section .footer_link {
    height: 24px;
    display: flex;
    justify-content: center;
}

.footer_section .footer_link a {
    text-decoration: none;
    margin: 0px 10px;
    color: #ECEBE5;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: lighter;
}

@media screen and (min-width:1530px) {

    .android-download-users-review {
        width: 100%;
        height: 307px;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 28px 28px 28px;
    }

    .android-get-google {
        height: 115px;
        width: 57%;
        min-width: 574px;
        max-width: 640px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 175px;
        margin-top: 35px;
        margin-bottom: 20px;
    }

    .android-download-review {
        width: 53% !important;
        min-width: 574px;
        max-width: 640px !important;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: -420px;
    }

    .addictiveApp_cards {
        height: 501px;
        width: 100vw;
        background: #DFDDD3;
        padding: 0px 60px;
        display: flex;
        align-items: center;
        justify-content: space-evenly !important;
    }
}

@media screen and (min-width:2800px) {
    .android-main-container {
        width: 100%;
        background: #ECEBE5;
        padding: 70px 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
    }

    .addictiveApp_cards {
        height: 501px !important;
        width: 100vw !important;
        background: #DFDDD3 !important;
        justify-content: space-around !important;
        padding: 0px 60px !important;
        display: flex !important;
        align-items: center !important;
    }

    .early_access_cards {
        height: 168px;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 180px;
    }

    .addictiveApp_cards {
        height: 501px;
        width: 100vw;
        background: #DFDDD3;
        padding: 0px 60px;
        display: flex;
        align-items: center;
        justify-content: space-evenly !important;
    }

    .android-download-users-review {
        width: 100%;
        height: 307px;
        background-color: #fff;
        border-radius: 8px;
        padding: 28px 28px 28px 28px;
    }
}

@media screen and (min-width:2000px) and (max-width:2799px) {

    .addictiveApp_cards {
        height: 501px !important;
        width: 100vw !important;
        background: #DFDDD3 !important;
        justify-content: space-around !important;
        padding: 0px 60px !important;
        display: flex !important;
        align-items: center !important;
    }

    .early_access_cards {
        height: 168px;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 180px;
    }

    .android-main-container {
        width: 100%;
        background: #ECEBE5;
        padding: 70px 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
    }
}

@media screen and (min-width:1600px) {
    .minimis_launcher .section1 button {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        -webkit-text-stroke: 0.1px #ECEBE5;
        width: 180px;
        font-weight: 300;
        line-height: normal;
        padding: 11px 4px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    .early_access_cards button {
        font-family: 'Inter', sans-serif;
        font-size: 16px !important;
        font-style: normal;
        -webkit-text-stroke: 0.1px #ECEBE5;
        width: 180px;
        font-weight: 500;
        line-height: normal;
        padding: 11px 2px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }
}

@media screen and (min-width: 1450px) {
    .call_newport_section {
        position: relative;
        width: 100vw;
        max-height: 399px;
        height: 399px;
        min-height: 399px;
        background: #151E1D;
        overflow: hidden;
        /* Ensures the image stays within the section */
    }

    .call_newport_section::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 399px;
        /* Fixed height for the background image */
        background-image: url('../../assests/launcher_page_img/black divider image.png');
        background-size: auto 399px;
        /* Ensures the image has a fixed height */
        background-position: right;
        background-repeat: no-repeat;
        z-index: 0;
    }

    .call_newport_section .call_newport_text {
        width: 52.5%;
        height: 100%;
        padding: 0px 168px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;
        /* Ensure text is above the pseudo-element */
    }

    .call_newport_section .call_newport_text .p1 {
        max-width: 450px;
        width: auto;
        min-width: 380px;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: lighter !important;
        line-height: 24px;
        color: #ECEBE5;
        letter-spacing: 0.3px;
        margin-bottom: -16px !important;
    }

    .call_newport_section .call_newport_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: lighter !important;
        line-height: 24px;
        color: #ecebe5bd !important;
        -webkit-text-stroke: 0px !important;
        letter-spacing: 0.5px;
    }

}

@media screen and (min-width: 1300px) and (max-width: 1419px) {
    .android-main-container {
        background: #ECEBE5;
        padding: 60px 130px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 65px;
    }

    .android-get-google {
        height: 100px;
        width: 107%;
        min-width: 520px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 152px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 36%;
        height: 62px;
        display: flex;
        align-items: center;
    }

    .android-get-google-text p {
        font-size: 30px;
        line-height: 36px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 36.55%;
        height: 62px;
        display: flex;
        align-items: center;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 420px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 555px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: -40%;
    }

    .android-download-playstore {
        width: 53.7%;
        height: 63px !important;
        align-items: center;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-users-review {
        width: 53.57%;
    }

    .android-download-users-review img {
        width: 100%;
        border-radius: 8px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1299px) {
    .android-main-container {
        background: #ECEBE5;
        padding: 60px 100px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 65px;
    }

    .android-get-google {
        height: 100px;
        width: 107%;
        min-width: 535px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 152px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 34%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-text p {
        font-size: 28px;
        line-height: 36px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 34%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 400px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 535px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: -40%;
    }

    .android-download-playstore {
        width: 53.5%;
        height: 63px !important;
        align-items: center;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-users-review {
        width: 53.5%;
    }

    .android-download-users-review img {
        width: 100%;
        border-radius: 8px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1199px) {
    .android-main-container {
        background: #ECEBE5;
        padding: 60px 100px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 65px;
    }

    .android-get-google {
        height: 100px;
        width: 98%;
        min-width: 460px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 120px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 40%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-text p {
        font-size: 24px;
        line-height: 36px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 40%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 375px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 460px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: -40%;
    }

    .android-download-playstore {
        width: 51.3%;
        height: 63px !important;
        align-items: center;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-users-review {
        width: 51.3%;
        height: 307px;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 24px 24px 24px;
    }

    .android-download-app-rating {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
    }

    .android-download-rating {
        width: 80%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        right: 1px;
        gap: 16px;
    }

    .android-download-app-review {
        width: 100%;
        margin-top: 15px;
    }

}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
    .minimis_launcher .section1 {
        width: 60%;
        margin-left: 100px;
        margin-top: 90px;
    }

    .minimis_launcher .section1 {
        width: 40%;
    }

    .minimis_launcher .section2 img {
        height: 600px;
        width: 470px;
        margin-left: 100px;
        margin-top: 30px;
    }

    .addictiveApp_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        width: 55%;
    }

    .minimis_launcher .section1 .p1 {
        width: 80% !important;
    }

    .minimis_launcher .section1 .p2 {
        width: 90%;
    }

    .addictiveApp_cards .addictiveApp_text {
        width: 45%;
        padding: 0px 0px 0px 30px;
    }

    .addictiveApp_cards .addictiveApp_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 27px;
        font-weight: lighter !important;
        line-height: 42px;
        -webkit-text-stroke: 0.5px black;
    }

    .addictiveApp_cards .addictiveApp_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        color: #2e2e2e !important;
        -webkit-text-stroke: 0px black;
        line-height: 24px;
        margin-top: 20px;
        letter-spacing: 0.3px;
    }
}

@media screen and (min-width: 950px) and (max-width: 1099px) {
    .minimis_launcher .section1 {
        width: 60%;
        margin-left: 60px;
        margin-top: 90px;
    }

    .minimis_launcher .section1 {
        width: 40%;
    }

    .minimis_launcher .section2 img {
        height: 594px;
        width: 483px;
        margin-top: 40px;
        margin-left: 30px;
    }

    .minimis_launcher .section1 .p1 {
        width: 80%;
    }

    .minimis_launcher .section1 .p2 {
        width: 90%;
    }

    .addictiveApp_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        width: 50%;
    }

    .addictiveApp_cards .app_cards img {
        height: 300px;
        width: 136px;
    }

    .addictiveApp_cards .addictiveApp_text {
        width: 50%;
        padding: 0px 0px 0px 30px;
    }

    .addictiveApp_cards .addictiveApp_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 27px;
        font-weight: lighter !important;
        line-height: 42px;
        -webkit-text-stroke: 0.5px black;
    }

    .addictiveApp_cards .addictiveApp_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        color: #2e2e2e !important;
        -webkit-text-stroke: 0px black;
        line-height: 24px;
        margin-top: 20px;
        letter-spacing: 0.3px;
    }

    .call_newport_section .call_newport_text {
        width: 630px;
        box-sizing: border-box;
        height: 100%;
        padding: 0px 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .timeLimit_cards .app_cards img {
        height: 430px;
        width: 215px;
    }

    .welcome_screen_cards .app_cards img {
        height: 430px;
        width: 215px;
    }

    .early_access_cards {
        height: 167px;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 78px 0px 78px;
    }

    /* ######## users review styling ######## */

    .android-main-container {
        background: #ECEBE5;
        padding: 40px 80px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 65px;
    }

    .android-get-google {
        height: 100px;
        width: 92%;
        min-width: 390px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 80px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 33%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-text p {
        font-size: 20px;
        line-height: 36px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 33%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 335px;
        margin-top: 100px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 390px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: -53.5%;
        margin-bottom: 30px !important;
    }

    .android-download-playstore {
        width: 49.5%;
        height: 63px !important;
        align-items: center;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-users-review {
        width: 49.5%;
        height: auto !important;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 24px 24px 24px;
    }

    .android-download-app-rating {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
    }

    .android-download-rating {
        width: 80%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        right: 1px;
        gap: 16px;
    }

    .android-download-app-review {
        width: 100%;
        margin-top: 15px;
    }

}

@media screen and (min-width: 850px) and (max-width: 949px) {
    .minimis_launcher {
        height: 550px;
        width: 100vw;
        background: #ECEBE5;
    }

    .minimis_launcher .section1 input {
        background: #DFDDD3;
        outline: none;
        border: none;
        padding: 11px 12px;
        width: auto;
        border-radius: 2px;
        margin-right: 4px;
    }

    .minimis_launcher .section1 .p1 {
        width: 80%;
    }

    .minimis_launcher .section1 .p2 {
        width: 90%;
    }

    .minimis_launcher .section1 {
        width: 60%;
        margin-left: 60px;
        margin-top: 70px;
    }

    .minimis_launcher .section1 {
        width: 40%;
    }

    .minimis_launcher .section2 img {
        height: 513px;
        width: 415px;
        margin-top: 40px;
        margin-left: 30px;
    }

    .addictiveApp_cards {
        height: 380px;
        width: 100vw;
        background: #DFDDD3;
        padding: 0px 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .minimis_launcher .section1 button {
        width: 100%;
        color: var(--primary-beige, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        -webkit-text-stroke: 0.1px #ECEBE5;
        width: 152px;
        font-weight: 300;
        line-height: normal;
        padding: 11px 3px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    .minimis_launcher .section1 .p1 {
        width: 100%;
    }

    .addictiveApp_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        width: 50%;
    }

    .addictiveApp_cards .app_cards img {
        height: 250px;
        width: 116px;
    }

    .addictiveApp_cards .addictiveApp_text {
        width: 50%;
        padding: 0px 0px 0px 30px;
    }

    .addictiveApp_cards .addictiveApp_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        font-weight: lighter !important;
        line-height: 30px;
        -webkit-text-stroke: 0.5px black;
    }

    .addictiveApp_cards .addictiveApp_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        color: #2e2e2e !important;
        -webkit-text-stroke: 0px black;
        line-height: 24px;
        margin-top: 14px;
        letter-spacing: 0.3px;
    }

    .call_newport_section {
        width: 100vw;
        height: 352px;
        background: #151E1D;
        background-image: url('../../assests/launcher_page_img/black\ divider\ image.png');
        background-size: cover;
        background-position: center;
    }

    .call_newport_section .call_newport_text {
        width: 580px;
        box-sizing: border-box;
        height: 100%;
        padding: 0px 65px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .timeLimit_cards {
        height: 531px;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeLimit_cards .app_cards img {
        height: 405px;
        width: 187px;
    }

    .welcome_screen_cards {
        height: 606px;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        flex-direction: column;
    }

    .welcome_screen_cards .app_cards img {
        height: 405px;
        width: 187px;
    }

    .early_access_cards {
        height: 167px;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 30px 0px 30px;
    }

    .early_access_cards input {
        background: #ECEBE5;
        outline: none;
        border: none;
        padding: 11px 12px;
        width: 208px;
        border-radius: 2px;
        margin-right: 4px;
    }

    /* ######## users review styling ######## */

    .android-main-container {
        background: #ECEBE5;
        padding: 30px 60px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .android-get-google {
        height: 80px;
        width: 90%;
        min-width: 320px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 60px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 40%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-text p {
        font-size: 20px;
        line-height: 36px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 33%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 300px;
        margin-top: 75px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 390px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: -55.6%;
    }

    .android-download-playstore {
        width: 49.5%;
        height: 63px !important;
        align-items: center;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-users-review {
        width: 49.5%;
        height: auto !important;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 24px 24px 24px;
    }

    .android-download-app-rating {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
    }

    .android-download-rating {
        width: 80%;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-end;
        right: 1px;
        gap: 0px;
    }

    .android-download-app-review {
        width: 100%;
        margin-top: 15px;
    }

    .footer_section {
        width: 100vw;
        height: 124px;
        background: #151E1D;
        display: flex;
        justify-content: space-between;
        padding: 0px 50px 0px 50px;
        align-items: center;
    }

    .footer_section .footer_link {
        height: auto !important;
        width: 92% !important;
        padding-left: 25px !important;
    }

    .footer_section .footer_link a {
        text-decoration: none;
        margin: 0px 8px;
        color: #ECEBE5;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: lighter;
    }

}

@media screen and (min-width: 650px) and (max-width: 849px) {
    .minimis_launcher .launcher_navbar {
        width: 100vw;
        padding: 30px 45px;
        background: #ECEBE5;
        position: fixed;
        display: flex !important;
        align-items: center !important;
    }

    .phone_mockup_section {
        padding-top: 120px;
    }

    .minimis_launcher {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
    }

    .phone_mockup_section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .minimis_launcher .section1 input {
        background: #DFDDD3;
        outline: none;
        border: none;
        padding: 11px 12px;
        width: 54vw;
        border-radius: 2px;
        margin-right: 4px;
    }

    .minimis_launcher .section1 button {
        width: 100%;
        color: var(--primary-beige, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-style: normal;
        -webkit-text-stroke: 0.1px #ECEBE5;
        width: 33vw;
        font-weight: 300;
        line-height: normal;
        padding: 11px 4px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    .minimis_launcher .section2 img {
        height: 584px;
        width: 466px;
        margin-right: 0px;
    }

    .minimis_launcher .section1 .p1 {
        width: 100%;
        padding: 0px !important;
    }

    .minimis_launcher .section1 .p2 {
        width: 100%;
    }

    .minimis_launcher .section1 {
        width: 100%;
    }

    .addictiveApp_cards .addictiveApp_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 30px;
        font-weight: lighter !important;
        line-height: 42px;
        padding: 0px !important;
    }

    .minimis_launcher .section1 {
        width: 100%;
        margin-left: 60px;
        margin-top: 0px;
        padding-left: 20px;
        padding-right: 80px;
    }

    .emailDiv {
        margin-top: 0px !important;
        margin-bottom: 40px;
    }

    .addictiveApp_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        padding: 0px 50px;
        flex-direction: column-reverse;
        align-items: center;
    }

    .early_access_cards input {
        background: #ECEBE5;
        outline: none;
        border: none;
        padding: 11px 12px;
        width: 52vw;
        border-radius: 2px;
        margin-right: 4px;
    }

    .early_access_cards button {
        width: 100%;
        color: var(--primary-beige, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 16px !important;
        font-style: normal;
        -webkit-text-stroke: 0.1px #ECEBE5;
        width: 34vw;
        font-weight: 500;
        line-height: normal;
        padding: 11px 2px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    .addictiveApp_cards .addictiveApp_text {
        width: 100%;
        padding: 30px 0px 30px 0px;
    }

    .addictiveApp_cards .app_cards img {
        height: auto;
        width: 20vw;
        margin-bottom: 30px;
    }

    .call_newport_section {
        width: 101vw;
        height: 324px;
        background: #151E1D;
        background-image: url('../../assests/launcher_page_img/black\ divider\ image.png');
        background-size: cover;
        background-position: 62%;
    }

    .call_newport_section .call_newport_text {
        width: 60%;
        box-sizing: border-box;
        height: 100%;
        padding: 0px 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .timeLimit_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeLimit_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: lighter !important;
        line-height: 30px;
        padding: 36px 28px 0px 28px !important;
    }

    .timeLimit_cards .app_cards img {
        height: auto;
        width: 20vw;
        margin: 50px 0px;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        flex-direction: column;
        padding: 0px !important;
    }

    .welcome_screen_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        font-weight: lighter !important;
        line-height: 42px;
        -webkit-text-stroke: 0.5px black;
        padding: 36px 0px;
    }

    .welcome_screen_cards .app_cards img {
        height: auto;
        width: 20vw;
        margin: 0px 0px 50px 0px;
    }

    .early_access_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        padding: 20px 0px 40px 0px;
        flex-direction: column;
    }

    .early_access_cards .p1 {
        text-align: center;
        padding: 0px 0px 12px 0px !important;
    }

    .footer_section {
        width: 100vw;
        height: auto;
        background: #151E1D;
        display: flex;
        justify-content: center;
        padding: 30px 30px 30px 30px;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
    }

    .footer_section .footer_link a {
        text-decoration: none;
        margin: 5px 0px;
    }

    .footer_section .footer-img {
        height: 24px;
        display: flex;
        justify-content: center;
        margin-bottom: 14px;
    }

    .footer_section .footer-img img {
        height: auto;
        width: auto;
    }

    .footer_section .footer_link {
        height: auto !important;
        display: flex;
        flex-direction: column;
        margin-left: 0px !important;
    }

    /* ######## users review styling ######## */

    .android-main-container {
        background: #ECEBE5;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .android-get-google {
        height: auto;
        width: 40% !important;
        min-width: 100px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .android-get-google-text p {
        font-size: 24px;
        line-height: 36px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-launched {
        display: block;
    }

    .android-get-google-launched p {
        font-size: 16px !important;
        line-height: 24px !important;
        font-weight: 300 !important;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 300px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 390px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 0px !important;
    }

    .android-download-playstore {
        width: 100%;
        height: 75px;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
        align-items: center !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-users-review {
        width: 100%;
        height: auto !important;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 24px 24px 24px;
    }

    .android-download-app-rating {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
    }

    .android-download-rating {
        width: 80%;
        display: flex;
        flex-direction: row !important;
        justify-content: flex-end;
        align-items: center;
        right: 1px;
        gap: 16px;
    }

    .android-download-app-review {
        width: 100%;
        margin-top: 15px;
    }

}

@media screen and (min-width: 500px) and (max-width: 649px) {
    .minimis_launcher .launcher_navbar {
        width: 100vw;
        padding: 30px 28px;
        background: #ECEBE5;
        position: fixed;
        display: flex !important;
        align-items: center !important;
    }

    .phone_mockup_section {
        padding-top: 120px;
    }

    .minimis_launcher {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
    }

    .phone_mockup_section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .minimis_launcher .section1 button {
        width: 32vw;
    }

    .minimis_launcher .section1 input {
        background: #DFDDD3;
        outline: none;
        border: none;
        padding: 11px 12px;
        width: 56vw;
        border-radius: 2px;
        margin-right: 4px;
    }

    .minimis_launcher .section2 img {
        height: 366px;
        width: 304px;
        margin-right: 0px;
    }

    .minimis_launcher .section1 .p1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 28px;
        font-weight: lighter !important;
        line-height: 42px;
        padding: 0px !important;
    }

    .minimis_launcher .section1 .p2 {
        width: 100%;
    }

    .minimis_launcher .section1 {
        width: 100%;
        margin-left: 60px;
        margin-top: 0px;
        padding-left: 0px;
        padding-right: 60px;
    }

    .emailDiv {
        margin-top: 0px !important;
        margin-bottom: 40px;
    }

    .addictiveApp_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        width: 60%;
    }

    .addictiveApp_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 40px;
        padding: 10px 28px 40px 28px;
    }

    .addictiveApp_cards .addictiveApp_text {
        width: 100%;
        padding: 16px 0px 16px 0px;
    }

    .addictiveApp_cards .addictiveApp_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 26px;
        font-weight: lighter !important;
        line-height: 42px;
        padding: 0px !important;
    }

    .addictiveApp_cards .addictiveApp_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        color: #2e2e2e !important;
        -webkit-text-stroke: 0px black;
        line-height: 24px;
        margin-top: 10px;
        letter-spacing: 0.4px;
    }

    .addictiveApp_cards .app_cards img {
        height: auto;
        width: 44vw;
    }

    .call_newport_section {
        width: 101vw;
        height: 260px;
        background: #151E1D;
        background-image: url('../../assests/launcher_page_img/black\ divider\ image.png');
        background-size: cover;
        background-position: 62%;
    }

    .call_newport_section .call_newport_text {
        width: 52%;
        box-sizing: border-box;
        height: 100%;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .call_newport_section .call_newport_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: lighter !important;
        line-height: 21px;
        color: #ECEBE5;
        letter-spacing: 0.3px;
        margin-bottom: -16px !important;
    }

    .call_newport_section .call_newport_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: lighter !important;
        line-height: 24px;
        color: #ecebe5bd !important;
        -webkit-text-stroke: 0px !important;
        letter-spacing: 1px;
    }

    .timeLimit_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeLimit_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: lighter !important;
        line-height: 30px;
        padding: 36px 28px 0px 28px !important;
    }

    .timeLimit_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        margin: 50px 0px;
    }

    .timeLimit_cards .app_cards img {
        height: auto;
        width: 44vw;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        flex-direction: column;
    }


    .welcome_screen_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        padding-bottom: 30px;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        align-items: center;
        padding: 0px !important;
    }

    .welcome_screen_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 21px;
        font-weight: lighter;
        line-height: 31px;
        -webkit-text-stroke: 0.5px black;
        padding: 29px 28px;
    }

    .welcome_screen_cards .app_cards img {
        height: auto;
        width: 44vw;
        margin: 0px 0px 0px 0px;
    }

    .early_access_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        padding: 20px 0px 40px 0px;
        flex-direction: column;
    }

    .early_access_cards .p1 {
        text-align: center;
    }

    .early_access_cards input {
        background: #ECEBE5;
        outline: none;
        border: none;
        padding: 11px 12px;
        width: 55vw;
        border-radius: 2px;
    }

    .early_access_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 27px;
        font-weight: lighter !important;
        padding: 0px 0px 8px 0px;
    }

    .early_access_cards button {
        color: var(--primary-beige, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 16px !important;
        font-style: normal;
        -webkit-text-stroke: 0.1px #ECEBE5;
        width: 33vw;
        font-weight: 500;
        line-height: normal;
        padding: 11px 2px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    .footer_section {
        width: 100vw;
        height: auto;
        background: #151E1D;
        display: flex;
        justify-content: center;
        padding: 30px 30px 30px 30px;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
    }

    .footer_section .footer_link a {
        text-decoration: none;
        margin: 5px 0px;
    }

    .footer_section .footer-img {
        height: 24px;
        display: flex;
        justify-content: center;
        margin-bottom: 14px;
    }

    .footer_section .footer-img img {
        height: auto;
        width: auto;
    }

    .footer_section .footer_link {
        height: auto !important;
        display: flex;
        flex-direction: column;
        margin-left: 0px !important;
    }

    /* ######## users review styling ######## */

    .android-main-container {
        background: #ECEBE5;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .android-get-google {
        height: auto;
        width: 40% !important;
        min-width: 100px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .android-get-google-text p {
        font-size: 24px;
        line-height: 36px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-launched {
        display: block;
    }

    .android-get-google-launched p {
        font-size: 16px !important;
        line-height: 24px !important;
        font-weight: 300 !important;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 53%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 100px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 390px;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 0px !important;
    }

    .android-download-playstore {
        width: 100%;
        height: 62px;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
        align-items: center !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-users-review {
        width: 100%;
        height: auto !important;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 24px 24px 24px;
    }

    .android-download-app-rating {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
    }

    .android-download-rating {
        width: 80%;
        display: flex;
        flex-direction: row !important;
        justify-content: flex-end;
        align-items: center;
        right: 1px;
        gap: 16px;
    }

    .android-download-app-review {
        width: 100%;
        margin-top: 12px;
    }

}

@media screen and (min-width: 430px) and (max-width: 499px) {
    .minimis_launcher .launcher_navbar {
        min-height: 80px !important;
        max-height: 85px !important;
        width: 100vw;
        padding: 25px 19px;
        background: #ECEBE5;
        position: fixed;
        display: flex !important;
        align-items: center !important;
    }

    .launcher_navbar .navLogo {
        height: 20px !important;
        width: 126px !important;
    }

    .phone_mockup_section {
        padding-top: 120px;
    }

    .minimis_launcher {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
    }

    .phone_mockup_section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .minimis_launcher .section2 img {
        height: 366px;
        width: 304px;
        margin-right: 0px;
    }

    .minimis_launcher .section1 .p1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: normal;
        line-height: 30px;
        padding: 0px !important;
    }

    .minimis_launcher .section1 .p2 {
        width: 100%;
        margin-top: 7px;
    }

    .minimis_launcher .section1 input {
        background: #DFDDD3;
        outline: none;
        border: none;
        padding: 11px 6px;
        width: 52vw;
        border-radius: 2px;
        margin-right: 4px;
    }

    .minimis_launcher .section1 button {
        width: 37vw;
        font-size: 14px;
    }

    .minimis_launcher .section1 {
        width: 100%;
        padding-left: 20px;
        padding-right: 23px;
        margin-left: 0px;
        margin-top: 0px;
    }

    .timeLimit_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: lighter !important;
        line-height: 30px;
        padding: 36px 16px 0px 16px !important;
    }

    .welcome_screen_cards,
    .p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        font-weight: lighter !important;
        line-height: 42px;
        -webkit-text-stroke: 0px #00000000 !important;
        padding: 36px 0px 36px 0px;
    }

    .emailDiv {
        margin-top: 0px !important;
        margin-bottom: 40px;
    }

    .addictiveApp_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        width: 60%;
    }

    .addictiveApp_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding: 19px 19px 30px 19px;
    }

    .addictiveApp_cards .addictiveApp_text {
        width: 100%;
        padding: 16px 0px 16px 0px;
    }

    .addictiveApp_cards .addictiveApp_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 26px;
        font-weight: lighter !important;
        line-height: 42px;
        padding: 0px !important;
    }

    .addictiveApp_cards .addictiveApp_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        color: #2e2e2e !important;
        -webkit-text-stroke: 0px black;
        line-height: 24px;
        margin-top: 10px;
        letter-spacing: 0.4px;
    }

    .addictiveApp_cards .app_cards img {
        height: auto;
        width: 44vw;
    }

    .call_newport_section {
        width: 100%;
        height: 250px;
        background: #151E1D;
        background-image: url('../../assests/launcher_page_img/Export\ this.png');
        background-size: cover;
        background-position: 65%;
        background-size: 100%;
    }

    .call_newport_section .call_newport_text {
        width: 57.5%;
        height: 100%;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .call_newport_section .call_newport_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        font-weight: lighter !important;
        line-height: 19px;
        color: #ECEBE5;
        letter-spacing: 0.px;
        margin-bottom: -16px !important;
    }

    .call_newport_section .call_newport_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: lighter !important;
        line-height: 20px;
        color: #ecebe5bd !important;
        -webkit-text-stroke: 0px !important;
        width: 60%;
        letter-spacing: 1px;
        padding-bottom: 36px !important;
    }

    .timeLimit_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeLimit_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        margin: 50px 0px;
    }

    .timeLimit_cards .app_cards img {
        height: auto;
        width: 44vw;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        flex-direction: column;
    }

    .welcome_screen_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 21px;
        font-weight: lighter !important;
        line-height: 42px;
        -webkit-text-stroke: 0.5px black;
        padding: 30px 0px;
    }

    .welcome_screen_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        padding-bottom: 30px;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        align-items: center;
        padding: 0px !important;
    }

    .welcome_screen_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 21px;
        font-weight: lighter;
        line-height: 31px;
        -webkit-text-stroke: 0.5px black;
        padding: 29px 25px
    }

    .welcome_screen_cards .app_cards img {
        height: auto;
        width: 44vw;
        margin: 0px 0px 0px 0px;
    }

    .early_access_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        padding: 20px 0px 40px 0px;
        flex-direction: column;
    }

    .early_access_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: lighter !important;
        text-align: center;
        -webkit-text-stroke: 0px black !important;
        padding: 0px;
    }

    .early_access_cards .p2 {
        padding: 0px 24px;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: lighter !important;
        -webkit-text-stroke: 0.2px black;
        line-height: 25px;
        letter-spacing: 0.3px;
    }

    .early_access_cards input {
        background: #ECEBE5;
        outline: none;
        border: none;
        width: 56vw;
        padding: 11px 12px;
        border-radius: 2px;
        margin-right: 4px;
    }

    .early_access_cards button {
        max-width: 34vw;
        color: var(--primary-beige, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 14px !important;
        -webkit-text-stroke: 0.1px #ECEBE5;
        font-weight: 500;
        line-height: 19px !important;
        padding: 11px 2px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    .footer_section {
        width: 100vw;
        height: auto;
        background: #151E1D;
        display: flex;
        justify-content: center;
        padding: 30px 30px 30px 30px;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
    }

    .footer_section .footer_link a {
        text-decoration: none;
        margin: 5px 0px;
    }

    .footer_section .footer-img {
        height: 24px;
        display: flex;
        justify-content: center;
        margin-bottom: 14px;
    }

    .footer_section .footer-img img {
        height: auto;
        width: auto;
    }

    .footer_section .footer_link {
        height: auto !important;
        display: flex;
        flex-direction: column;
        margin-left: 0px !important;
    }

    /* ######## users review styling ######## */

    .android-main-container {
        background: #ECEBE5;
        padding: 30px 16px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .android-get-google {
        height: auto;
        width: 50% !important;
        min-width: 100px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .android-get-google-text p {
        font-size: 24px;
        line-height: 36px;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-launched {
        display: block;
        width: 80% !important;
    }

    .android-get-google-launched p {
        font-size: 16px !important;
        line-height: 24px !important;
        font-weight: 300 !important;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 70% !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 100px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 120px !important;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 0px !important;
    }

    .android-download-playstore {
        width: 100%;
        height: auto;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
        flex-direction: column;
        align-items: center !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-playstore-review {
        width: 100%;
        padding-top: 5px;
    }

    .android-download-playstore-review img {
        width: 75%;
        padding-left: 10px;
    }

    .android-download-users-review {
        width: 100%;
        height: auto !important;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 24px 28px 24px;
    }

    .android-download-app-rating {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
    }

    .android-play-store-icon {
        width: 50%;
        display: flex;
        justify-content: center;
    }

    .android-play-store-icon img {
        object-fit: contain !important;
    }

    .android-download-rating {
        width: 60%;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-end;
        right: 1px;
        gap: 0px;
    }

    .android-download-rating-text p {
        color: #2e2e2e;
        opacity: 50%;
    }

    .android-download-app-review {
        width: 100%;
        margin-top: 12px;
    }

    .android-download-app-review-light-text {
        font-family: 'Inter', system-ui !important;
        font-size: 16px !important;
        font-weight: 300 !important;
        line-height: 24px !important;
        color: black !important;
        opacity: 90% !important;
    }

    /* .android-download-app-review p {
        display: inline;
        font-weight: 500 !important;
        font-family: 'Inter', sans-serif !important;
        font-size: 16px !important;
        line-height: 22px !important;
        text-align: center !important;
    } */

}

@media screen and (min-width: 385px) and (max-width: 429px) {
    .minimis_launcher .launcher_navbar {
        min-height: 80px !important;
        max-height: 85px !important;
        width: 100vw;
        padding: 24px 19px;
        height: auto !important;
        background: #ECEBE5;
        position: fixed;
        display: flex !important;
        align-items: center !important;
    }

    .launcher_navbar .navLogo {
        height: 20px !important;
        width: 126px !important;
    }

    .phone_mockup_section {
        padding-top: 100px !important;
    }

    .minimis_launcher {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
    }

    .phone_mockup_section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .minimis_launcher .section2 img {
        height: 366px;
        width: 304px;
        margin-right: 0px;
    }

    .minimis_launcher .section1 .p1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: normal;
        line-height: 30px;
        padding: 0px !important;
    }

    .minimis_launcher .section1 .p2 {
        width: 100%;
        margin-top: 7px;
    }

    .minimis_launcher .section1 input {
        background: #DFDDD3;
        outline: none;
        border: none;
        padding: 11px 6px;
        width: 53vw;
        border-radius: 2px;
        margin-right: 4px;
    }

    .minimis_launcher .section1 button {
        width: 36vw;
        font-size: 14px;
    }

    .minimis_launcher .section1 {
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .timeLimit_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: lighter !important;
        line-height: 30px;
        -webkit-text-stroke: 0px #000 !important;
        padding: 36px 16px 00px 16px !important;
    }

    .welcome_screen_cards,
    .p1 {
        font-family: "Inter", sans-serif;
        font-size: 24px;
        font-weight: lighter !important;
        line-height: 42px;
        -webkit-text-stroke: 0px #00000000 !important;
        padding: 36px 0px 12px 0px;
    }

    .emailDiv {
        margin-top: 0px !important;
        margin-bottom: 40px;
    }

    .addictiveApp_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 12px;
        width: 60%;
    }

    .addictiveApp_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding: 16px 16px 20px 16px;
    }

    .addictiveApp_cards .addictiveApp_text {
        width: 100%;
        padding: 16px 0px 24px 0px;
    }

    .addictiveApp_cards .addictiveApp_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: 400 !important;
        line-height: 30px;
        padding: 0px !important;
    }

    .addictiveApp_cards .addictiveApp_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        color: #2e2e2e !important;
        -webkit-text-stroke: 0px black;
        line-height: 24px;
        margin-top: 12px;
        letter-spacing: 0.4px;
    }

    .addictiveApp_cards .app_cards img {
        height: auto;
        width: 44.875vw;
        margin-bottom: 4px;
    }

    .call_newport_section {
        width: 100%;
        height: 236px;
        background: #151E1D;
        background-image: url('../../assests/launcher_page_img/Export\ this.png');
        background-size: cover;
        background-position: 85%;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .call_newport_section .call_newport_text {
        width: 51%;
        height: auto !important;
        padding: 0px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .call_newport_section .call_newport_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        font-weight: lighter !important;
        line-height: 19px;
        color: #ECEBE5;
        letter-spacing: 0.1px;
        margin-bottom: -16px !important;
    }

    .call_newport_section .call_newport_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 13px;
        font-weight: 300 !important;
        line-height: 19px;
        color: #ecebe5bd !important;
        -webkit-text-stroke: 0px !important;
        width: 70%;
        padding-bottom: 36px;
    }

    .timeLimit_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeLimit_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 12px;
        margin: 36px 0px 32px 0px;
    }

    .timeLimit_cards .app_cards img {
        height: auto;
        width: 44.36vw;
        margin-bottom: 4px;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        flex-direction: column;
        padding: 0px !important;
    }

    .welcome_screen_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 12px;
        padding-bottom: 32px;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        align-items: center;
    }

    .welcome_screen_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 21px;
        font-weight: lighter;
        line-height: 31px;
        -webkit-text-stroke: 0.5px black;
        padding: 36px 16px
    }

    .welcome_screen_cards .app_cards img {
        height: auto;
        width: 44.36vw;
        margin-bottom: 4px;
    }

    .early_access_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        padding: 36px 0px 36px 0px;
        flex-direction: column;
    }

    .early_access_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: lighter !important;
        line-height: 30px !important;
        text-align: center;
        margin-bottom: 12px !important;
        padding: 0px !important;
    }

    .early_access_cards .p2 {
        padding: 0px 21px;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        -webkit-text-stroke: 0px black;
        color: #2e2e2e !important;
        line-height: 24px;
    }

    .early_access_cards input {
        background: #ECEBE5;
        outline: none;
        border: none;
        padding: 11px 12px;
        width: 55vw;
        border-radius: 2px;
        margin-right: 0px;
        margin-left: 21px;
    }

    .early_access_cards button {
        max-width: 35vw;
        margin-right: 16px;
        margin-left: 7px;
        color: var(--primary-beige, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 14px !important;
        -webkit-text-stroke: 0.1px #ECEBE5;
        font-weight: 500;
        line-height: 19px !important;
        padding: 11px 2px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
    }

    .footer_section {
        width: 100vw;
        height: auto;
        background: #151E1D;
        display: flex;
        justify-content: center;
        padding: 36px 0px 36px 16px;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
    }

    .footer_section .footer_link a {
        text-decoration: none;
        margin: 0px 0px 20px 0px;
    }

    .footer_section .footer-img {
        height: 24px;
        display: flex;
        justify-content: center;
        margin-bottom: 32px;
    }

    .footer_section .footer-img img {
        height: auto;
        width: auto;
    }

    .footer_section .footer_link {
        height: auto !important;
        display: flex;
        flex-direction: column;
        margin-left: 0px !important;
    }

    /* ######## users review styling ######## */

    .android-main-container {
        background: #ECEBE5;
        padding: 30px 16px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .android-get-google {
        height: auto;
        width: 50% !important;
        min-width: 100px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 12px;
        margin-right: 12px;
        gap: 10px;
    }

    .android-get-google-text p {
        font-size: 20px !important;
        line-height: 30px !important;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-launched {
        display: block;
        width: 80% !important;
    }

    .android-get-google-launched p {
        font-size: 16px !important;
        line-height: 24px !important;
        font-weight: 300 !important;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        padding-left: 12px;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 100px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 120px !important;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 0px !important;
    }

    .android-download-playstore {
        width: 100%;
        height: auto;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
        flex-direction: column;
        align-items: center !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-playstore-review {
        width: 100%;
        padding-top: 5px;
    }

    .android-download-playstore-review img {
        width: 75%;
        padding-left: 10px;
    }

    .android-download-users-review {
        width: 100%;
        height: auto !important;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 28px 28px 28px;
    }

    .android-download-app-rating {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
    }

    .android-play-store-icon {
        width: 50%;
        display: flex;
        justify-content: center;
    }

    .android-play-store-icon img {
        object-fit: contain !important;
    }

    .android-download-rating {
        width: 60%;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-end;
        right: 1px;
        gap: 0px;
    }

    .android-download-app-review {
        width: 100%;
        margin-top: 12px;
        letter-spacing: 0.2px;
    }

    .android-download-app-review-light-text {
        font-family: 'Inter', system-ui !important;
        font-size: 16px !important;
        font-weight: 300 !important;
        line-height: 24px !important;
        color: black !important;
        opacity: 90% !important;
    }

}

@media screen and (max-width: 384px) {
    .minimis_launcher .launcher_navbar {
        min-height: 80px !important;
        max-height: 85px !important;
        width: 100vw;
        padding: 25px 19px;
        background: #ECEBE5;
        position: fixed;
        display: flex !important;
        align-items: center !important;
    }

    .launcher_navbar .navLogo {
        height: 20px !important;
        width: 126px !important;
    }

    .phone_mockup_section {
        padding-top: 120px;
    }

    .minimis_launcher {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
    }

    .phone_mockup_section {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .minimis_launcher .section2 img {
        height: 366px;
        width: 304px;
        margin-right: 0px;
    }

    .minimis_launcher .section1 .p1 {
        width: 100%;
        font-family: "Inter", sans-serif;
        font-size: 18px;
        font-weight: normal;
        line-height: 30px;
        padding: 0px !important;
    }

    .minimis_launcher .section1 .p2 {
        width: 100%;
        margin-top: 7px;
    }

    .minimis_launcher .section1 input {
        background: #DFDDD3;
        outline: none;
        border: none;
        padding: 11px 6px;
        width: 51vw;
        border-radius: 2px;
        margin-right: 4px;
    }

    .minimis_launcher .section1 input::placeholder {
        font-size: 13px;
    }

    .minimis_launcher .section1 button {
        width: 36vw;
        font-size: 12px;
        padding: 12px 4px !important;
    }

    .minimis_launcher .section1 {
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
        padding-left: 20px;
        padding-right: 23px;
    }

    .timeLimit_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: lighter !important;
        line-height: 30px;
        -webkit-text-stroke: 0px #000 !important;
        padding: 36px 16px 00px 16px !important;
    }

    .emailDiv {
        margin-top: 0px !important;
        margin-bottom: 40px;
    }

    .addictiveApp_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        width: 60%;
    }

    .addictiveApp_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        padding: 19px 19px 30px 19px;
    }

    .addictiveApp_cards .addictiveApp_text {
        width: 100%;
        padding: 16px 0px 16px 0px;
    }

    .addictiveApp_cards .addictiveApp_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 21px;
        font-weight: lighter !important;
        line-height: 25px;
        padding: 0px !important;
    }

    .addictiveApp_cards .addictiveApp_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        color: #2e2e2e !important;
        -webkit-text-stroke: 0px black;
        line-height: 24px;
        margin-top: 10px;
        letter-spacing: 0.4px;
    }

    .addictiveApp_cards .app_cards img {
        height: auto;
        width: 44vw;
    }

    .call_newport_section {
        width: 100%;
        height: 205px;
        background: #151E1D;
        background-image: url('../../assests/launcher_page_img/Export\ this.png');
        background-position: 65%;
        background-size: cover !important;
    }

    .call_newport_section .call_newport_text {
        width: 57.5%;
        height: 100%;
        padding: 0px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .call_newport_section .call_newport_text .p1 {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: lighter !important;
        line-height: 16px;
        color: #ECEBE5;
        margin-bottom: -16px !important;
        padding: 10px 0px;
    }

    .call_newport_section .call_newport_text .p2 {
        font-family: "Inter", sans-serif;
        font-size: 12px;
        font-weight: lighter !important;
        line-height: 18px;
        color: #ecebe5bd !important;
        -webkit-text-stroke: 0px !important;
        width: 82%;
        letter-spacing: 1px;
    }

    .timeLimit_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timeLimit_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        margin: 50px 0px;
    }

    .timeLimit_cards .app_cards img {
        height: auto;
        width: 44vw;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        flex-direction: column;
    }


    .welcome_screen_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 21px;
        font-weight: lighter !important;
        line-height: 42px;
        -webkit-text-stroke: 0.5px black;
        padding: 30px 0px;
    }

    .welcome_screen_cards .app_cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        padding-bottom: 30px;
    }

    .welcome_screen_cards {
        height: auto;
        width: 100vw;
        background: #ECEBE5;
        display: flex;
        align-items: center;
        padding: 0px !important;
    }

    .welcome_screen_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 21px;
        font-weight: lighter;
        line-height: 31px;
        -webkit-text-stroke: 0.5px black;
        padding: 29px 21px
    }

    .welcome_screen_cards .app_cards img {
        height: auto;
        width: 44vw;
        margin: 0px 0px 0px 0px;
    }

    .early_access_cards {
        height: auto;
        width: 100vw;
        background: #DFDDD3;
        display: flex;
        align-items: center;
        padding: 20px 0px 40px 0px;
        flex-direction: column;
    }

    .early_access_cards .p1 {
        font-family: "Inter", sans-serif;
        font-size: 20px;
        font-weight: lighter !important;
        text-align: center;
        padding: 0px !important;
    }

    .early_access_cards .p2 {
        padding: 0px 21px;
        text-align: center;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 300 !important;
        -webkit-text-stroke: 0px black;
        color: #2e2e2e !important;
        line-height: 24px;
    }

    .early_access_cards input {
        background: #ECEBE5;
        outline: none;
        border: none;
        padding: 11px 12px;
        width: 53vw;
        border-radius: 2px;
        margin-right: 4px;
    }

    .early_access_cards input::placeholder {
        font-size: 12px;
    }

    .early_access_cards button {
        color: var(--primary-beige, #ECEBE5);
        font-family: 'Inter', sans-serif;
        font-size: 12px !important;
        -webkit-text-stroke: 0px #ECEBE5;
        font-weight: 500;
        line-height: 19px !important;
        padding: 11px 2px;
        color: white;
        background: #2e2e2e;
        outline: #2e2e2e;
        border: none;
        border-radius: 2px;
        width: 35vw;
    }

    .footer_section {
        width: 100vw;
        height: auto;
        background: #151E1D;
        display: flex;
        justify-content: center;
        padding: 30px 30px 30px 30px;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: flex-start;
    }

    .footer_section .footer_link a {
        text-decoration: none;
        margin: 5px 0px;
    }

    .footer_section .footer-img {
        height: 24px;
        display: flex;
        justify-content: center;
        margin-bottom: 14px;
    }

    .footer_section .footer-img img {
        height: auto;
        width: auto;
    }

    .footer_section .footer_link {
        height: auto !important;
        display: flex;
        flex-direction: column;
        margin-left: 0px !important;
    }

    /* ######## users review styling ######## */

    .android-main-container {
        background: #ECEBE5;
        padding: 30px 16px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .android-google-container {
        width: 100% !important;
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .android-get-google {
        height: auto;
        width: 50% !important;
        min-width: 100px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
        margin-top: 35px;
        margin-bottom: 0px !important;
    }

    .android-get-google-text {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 12px;
        margin-right: 12px;
        gap: 10px;
    }

    .android-get-google-text p {
        font-size: 18px !important;
        line-height: 30px !important;
        font-weight: 300;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-launched {
        display: block;
        width: 80% !important;
    }

    .android-get-google-launched p {
        font-size: 16px !important;
        line-height: 24px !important;
        font-weight: 300 !important;
        font-family: 'Inter', sans-serif;
    }

    .android-get-google-image {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        padding-left: 12px;
    }

    .android-get-google-image img {
        width: 100%;
    }

    .android-google-mobile {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .android-google-mobile img {
        width: 100%;
        min-width: 100px;
    }

    .android-download-review {
        width: 100% !important;
        min-width: 120px !important;
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 0px !important;
    }

    .android-download-playstore {
        width: 100%;
        height: auto;
        display: flex !important;
        background-color: #202124 !important;
        border-radius: 8px !important;
        flex-direction: column;
        align-items: center !important;
    }

    .android-download-playstore img {
        width: 100%;
    }

    .android-download-playstore-review {
        width: 100%;
        padding-top: 5px;
    }

    .android-download-playstore-review img {
        width: 75%;
        padding-left: 10px;
    }

    .android-download-users-review {
        width: 100%;
        height: auto !important;
        background-color: #fff;
        border-radius: 8px;
        padding: 16px 24px 28px 24px;
    }

    .android-download-app-rating {
        width: 100%;
        display: flex;
        margin-bottom: 12px;
    }

    .android-play-store-icon {
        width: 50%;
        display: flex;
        justify-content: center;
    }

    .android-play-store-icon img {
        object-fit: contain !important;
        padding-left: 24px;
    }

    .android-download-rating {
        width: 60%;
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: flex-end;
        right: 1px;
        gap: 0px;
    }

    .android-download-app-review {
        width: 100%;
        margin-top: 12px;
    }

    .android-download-app-review-light-text {
        font-family: 'Inter', system-ui !important;
        font-size: 16px !important;
        font-weight: 300 !important;
        line-height: 24px !important;
        color: black !important;
        opacity: 90% !important;
    }

}